<template>
  <a-form-item :ref='question.type' :name='question.id' :label='`${question.title}${question.validations.required == "1" ? " *" : ""}`'
               class='form-font mx-3' :help="errors && errors?.[question.id] ? errors?.[question.id] : ''"
               :validate-status="errors && errors?.[question.id] ? 'error' : ''">
    <a-input v-if='question.type === questionTypes.shortAnswer' :value='modelValue' placeholder='' class='input-field'
             @input="(event) => $emit('update:modelValue', event.target.value)" :readonly='readOnly' />
    <a-input v-if='question.type === questionTypes.largeAnswer' :value='modelValue' placeholder=''
             class='input-field' @input="(event) => $emit('update:modelValue', event.target.value)" :readonly='readOnly' />

    <a-input v-if='question.type === questionTypes.date' type='date' :value='modelValue' placeholder=''
             class='input-field' @change="(event) => $emit('update:modelValue', event.target.value)" :readonly='readOnly'/>

    <a-input v-if='question.type === questionTypes.time' type='time' :value='modelValue' placeholder=''
             class='input-field' @change="(event) => $emit('update:modelValue', event.target.value)" :readonly='readOnly'/>

    <a-radio-group :value='modelValue' v-if='question.type === questionTypes.multipleChoice'
                   @change="(event) => $emit('update:modelValue', event.target.value)" :readonly='readOnly' :disabled='readOnly'>
      <a-radio v-for='option in question.options' :value='option.id' :key='option.id'>{{option.content}}</a-radio>
    </a-radio-group>
    <a-checkbox-group @change="(event) => $emit('update:modelValue', event)" v-if='question.type === questionTypes.cbx'
                      :readonly='readOnly' :disabled='readOnly'>
      <a-row>
        <a-col :span="24" v-for='option in question.options' :key='option.id'>
          <a-checkbox :value="option.id" :disabled='readOnly'>{{option.content}}</a-checkbox>
        </a-col>
      </a-row>
    </a-checkbox-group>
    <a-row :gutter="30" v-if="question.type === questionTypes.file">
      <template v-if="readOnly">
        <a-col :lg="24" v-for="(file, i) in modelValue" :key="i">
          <button class="btn-add-outlined" @click="handleDownloadFile(file)">
            <sdFeatherIcons type="file" style="color:#E8394D;" />
            {{file.name}}
          </button>
        </a-col>
      </template>
      <template v-else :style='{width: "100%"}'>
        <a-col :xl="24"  :lg="24"  :md="24" :xs="24">
          <a-form-item ref="files " name="files" class="title-normal">
            <a-upload
              name="files"
              accept=".pdf, .png, .jpg, .jpng, .xls, .xml, .xlsx, .xmlx, .doc, .docx"
              :multiple="true"
              :fileList="modelValue"
              :remove="deleteFile"
              :beforeUpload="chooseFiles"
            >
              <button class="btn-add-outlined">
                <img
                  :src="require(`@/static/img_events/SEJ enventos_adjuntos.svg`)"
                  alt="logo"
                  width="16"
                  height="16"
                  style="margin-right: 0.6rem"
                />
                <span class="title-normal"> Adjuntar archivos </span>
              </button>
            </a-upload>
          </a-form-item>
        </a-col>
        <a-col :xl="24"  :lg="24"  :md="24" :xs="24" :style='{lineHeight: "2rem"}'>
          <span class="form-font helper-text">
            Los archivos seleccionados no deben pesar más de 20 MB en conjunto. Archivos permitidos: pdf, jpg,
            png, jpng, word, excel.
          </span>
        </a-col>
      </template>
    </a-row>
    <a-select v-if='question.type===questionTypes.dropDown' :value='modelValue'
              @change="(event) => $emit('update:modelValue', event)" :readonly='readOnly' :disabled='readOnly'>
      <a-select-option v-for='option in question.options' :value='option.id' :key='option.id' :readonly='readOnly' >
        {{option.content}}
      </a-select-option>

    </a-select>
    <a-input v-if='question.type === questionTypes.cct' :value='modelValue' placeholder=''
             class='input-field'  @input="(event) => $emit('update:cctmodelValue', event.target.value)" :readonly='readOnly'/>
    <a-input v-if='question.type === questionTypes.cct_fill' :value='modelValue' placeholder='' class='input-field' disabled/>
  </a-form-item>
  <div v-if='question.type === questionTypes.matrixTxt' >

  <table>
    <thead>
      <tr>
        <th></th>
        <th v-for='col in question.matrixOptions.cols' :key='col.id' >{{col.content}}</th>
      </tr>
    </thead>
    <tbody>
    <tr  v-for='row in question.matrixOptions.rows' :key='row.id'>
      <td> {{row.content}}</td>
      <td v-for='col in question.matrixOptions.cols' :key='col.id'>
        <a-form-item :ref='`T_${col.id}_${row.id}`' :name='`T_${col.id}_${row.id}`' label='' class='form-font mx-3'
                      :help="errors && errors?.[`T_${col.id}_${row.id}`] ? errors?.[`T_${col.id}_${row.id}`] : ''"
                            :validate-status="errors && errors?.[`T_${col.id}_${row.id}`] ? 'error' : ''"
        >
          <a-input
            :name='`T_${col.id}_${row.id}`'
            :value='modelValue[`T_${col.id}_${row.id}`]'
            class='input-field'
            @input="handleChangeInputMatrix"
            :readonly='readOnly'
          />
        </a-form-item>
      </td>
    </tr>

    </tbody>


  </table>


  </div>


</template>
<script>


import { formatDate } from '@/utility/utility';
import { ref } from 'vue';
import * as questionTypes from '@/constants/QuestionTypes.js';
import { Notification } from 'ant-design-vue';
import { getFileDetail } from '@/services/events'

export default {
  name: 'QuestionRender',
  components: {

  },
  props:["question", "modelValue", "readOnly", "errors"],
  mounted() {

  },
  data() {
    return {
      totalWeight: 0
    }
  },
  emits:[
    'update:modelValue',
  ],
  methods: {
    handleChangeInputMatrix(event){

      const contentMatrix = {
        ...this.modelValue,
          [`${event.target.name}`]: event.target.value,
      }
      //contentMatrix[`${event.target.name}`] = event.target.value
      this.$emit('update:modelValue', contentMatrix)

    },
    async handleSubmit(){

    },
    onChangeCbx(checkedValues) {
      console.log('checked = ', checkedValues);
    },
    handleChangeFile(info){
      //pendiente validar
      console.log('adsadsaasdads')
      if(this.modelValue.length===1){
        Notification['error']({message: 'Limite maximo de archivos', description: ''})
        return
      }
      if (info.file.status === 'done') {
        this.$emit('update:modelValue', info.fileList)
      }
      Notification['success']({
        message: 'Success',
        description: info.fileList.length,
      });
    },
    handleError(message) {
      Notification['error']({
        message: 'Error',
        description:
          message,
      });
    },
    chooseFiles(file) {
      const maxWeight = 20971520; //20 megabytes
      const auxWeight = this.totalWeight + file.size;
      if (auxWeight <= maxWeight && this.modelValue.length < 5) {
        let files = this.modelValue;
        console.log(files)
        console.log(file)
        files.push(file);
        this.$emit('update:modelValue', files);
        this.totalWeight = auxWeight;
        console.log(this.totalWeight);
      } else {
        const message = this.modelValue.length >= 5
          ? "Límite de archivos alcanzado"
          : `El documento ${file.name} excede el peso permitido en la carga de archivos (20Mb).`
        this.handleError(message);
      }
      return false;
    },
    deleteFile(file) {
      const index = this.modelValue.indexOf(file);
      const files = this.modelValue;
      this.totalWeight = this.totalWeight - file.size;
      files.splice(index, 1);
      this.$emit('update:modelValue', files);
    },
    handleDownloadFile(file) {
      getFileDetail(file.route).then((response) => {
        if (response && response.success !== false) { //Si es blob no tiene propiedad success, si falla success es false
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file.name);
          // Append to html page
          document.body.appendChild(link);
          // Force download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
      }).catch(() => {
        this.handleError("No se pudo descargar el archivo")
      })
    },
  },
  async setup() {

    const formRef = ref();


    return {
      formatDate,
      formRef,
      questionTypes

    };
  },

};

</script>

<style scoped lang='sass'>
.participant-form
  border-radius: 21px
  background-color: #FFFFFF

  margin: 32px
  width: 100%
  height: auto

  .general-form
    padding: 32px 21px
  .event-form
    padding: 32px 21px

  .title
    font-family: 'Nutmeg Bold'
    color: #404040
    font-size: 18px



.form__buttons
  padding: 32px 21px

  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px


.disabled
  color: #7B868C !important

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px
button:hover
  cursor: pointer
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem

.btn-add-outlined
  background-color: white
  border: 2px solid #e70e4c
  border-radius: 20px
  color: #e70e4c
  padding: .5rem 1.5rem

.btn-add-outlined.disabled
  border: 2px solid #CCCCCC
  color: #CCCCCC

</style>