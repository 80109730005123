<template>
  <Main>
    <FormValidationWrap>
      <VerticalFormStyleWrap>
        <a-col 
          :xxl="24" 
          :lg="24" 
          :md="24"
          :xs="24"
          v-if="loading">
            <sdCards>
                <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
            </sdCards>
        </a-col>
        <a-form
          v-else
          name='sDash_validation-form'
          ref='formRef'
          :model='dataForm'
          layout='vertical'
        >
          <a-row :gutter='25'>
            <div class='participant-form'>
              <div class='general-form'>
                <a-row>
                  <a-col :xxl='24' :lg='24' :xs='24' class='mb-2'>
                    <span class='title'>Información general</span>
                  </a-col>
                  <a-row :gutter='25'>
                    <a-col :xxl='5' :lg='7' :xs='24'>
                      <a-form-item ref='curp' name='curp' label='CURP' class='form-font mx-3 disabled'>
                        <a-input v-model:value='dataForm.curp' placeholder='' class='input-field' disabled />
                      </a-form-item>
                    </a-col>
                    <a-col :xxl='5' :lg='7' :xs='24'>
                      <a-form-item ref='name' name='name' label='Nombre(s)' class='form-font mx-3 disabled'>
                        <a-input v-model:value='dataForm.name' placeholder='' class='input-field' disabled />
                      </a-form-item>
                    </a-col>
                    <a-col :xxl='5' :lg='7' :xs='24'>
                      <a-form-item ref='lastname' name='lastname' label='Apellido Paterno' class='form-font mx-3'>
                        <a-input v-model:value='dataForm.lastname' placeholder='' class='input-field' disabled />
                      </a-form-item>
                    </a-col>
                    <a-col :xxl='5' :lg='7' :xs='24'>
                      <a-form-item ref='lastname2' name='lastname2' label='Apellido Materno' class='form-font mx-3'>
                        <a-input v-model:value='dataForm.lastname2' placeholder='' class='input-field' disabled />
                      </a-form-item>
                    </a-col>
                    <a-col :xxl='5' :lg='7' :xs='24'>
                      <a-form-item ref='email' name='email' label='Correo electrónico' class='form-font mx-3'>
                        <a-input v-model:value='dataForm.email' placeholder='' class='input-field' disabled />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-row>
              </div>
              <div v-if='dynamicForm' style='border-top: 1px solid #CCCCCC'></div>
              <div class='event-form' v-if='dynamicForm'>
                <a-col :xxl='24' :lg='24' :xs='24'>
                  <a-row :gutter='25'>
                    <a-col :xxl='24' :lg='24' :xs='24' class='mb-2'>
                      <span class='title'>Información para el evento</span>
                    </a-col>
                    <a-col  :xxl='24' :lg='24' :xs='24' class='mb-2'>
                      <span v-if='dynamicForm.sections.length > 1 ' class='title'>{{`Sección ${tabValue +1}` }}</span>
                    </a-col>
                    <a-col :xxl='24' :lg='24' :xs='24' class='mb-2'>
                      <a-row :gutter='25'>
                        <a-col :xxl='11' :lg='11' :xs='24' v-for='(question, idx) in dynamicForm.sections[tabValue].questions'
                               :key='question.id'>

                            <question-render
                              :modelValue='answers[question.id].content'
                              :question='question'
                              :errors='errors'
                              @update:modelValue="handleUpdateValue(answers[question.id],$event, question)"
                              @update:cctmodelValue="searchCCT($event, question, tabValue, idx)"
                            />

                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </a-col>
              </div>
            </div>
          </a-row>
          <a-row>
            <a-col 
              :xxl="24" 
              :lg="24" 
              :md="24"
              :xs="24"
              v-if="sendFormLoading">
                <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;"/>
            </a-col>
            <div class='form__buttons' v-else>
              <button class='btn-outlined-exit' @click='handleCancel'>
                <span class='title-normal'> Cancelar </span>
              </button>
              <button class='btn-fill-add'
                      @click='handleValidateTypeQuestion(dynamicForm.sections[tabValue].questions, tabValue, false)'
                      v-if="tabValue > 0">
                <span class='title-normal'> Ir a la sección anterior </span>
              </button>
              <button class='btn-fill-add'
                      @click='handleValidateTypeQuestion(dynamicForm.sections[tabValue].questions, tabValue, true)'
                      v-if="tabValue < dynamicForm?.sections?.length - 1 && !endForm">
                <span class='title-normal'> Ir a la siguiente sección </span>
              </button>
              <button class='btn-fill-add' v-on:click='handleShowModalToSubmit' v-else>
                <span class='title-normal'> Terminar registro </span>
              </button>

            </div>
          </a-row>
        </a-form>
      </VerticalFormStyleWrap>
    </FormValidationWrap>

  </Main>

  <sdModal
    type="primary"
    :width="300"
    :visible="showModal"
    :onCancel="onCancelModal"
    class="title-bold-normal">
    <a-row :gutter="25" class="mt-4"  style="text-align: center;">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
        <h3 class="title-normal">
          ¿Deseas agregar tu evento al calendario?
        </h3>
      </a-col>
    </a-row>
    <a-row :gutter="25"  style="text-align: center; margin-top: 2rem;">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24" >
        <a-button :loading="sendFormLoading" class="btn-outlined-exit" v-on:click="handleSubmit(false)">
          <span class="title-normal"> No </span>
        </a-button>
        <a-button :loading="sendFormLoading" class="btn-fill-add" v-on:click="handleSubmit(true)">
          <span class="title-normal"> Sí </span>
        </a-button>
      </a-col>
    </a-row>
  </sdModal>

</template>
<script>
import { Main } from '../styled';

import { formatDate } from '@/utility/utility';
import { useStore } from 'vuex';
import { getDetailsEvent, registerToEvent, registerToEventWithSubevents } from '@/services/events';
import { useRoute, useRouter } from 'vue-router';
import { getItem, removeItem } from '@/utility/localStorageControl';
import { ref } from 'vue';
import QuestionRender from '@/view/home/overview/QuestionRender';
import {getQuestionLevel3CCT} from '@/services/home';
import { Notification } from 'ant-design-vue';
import { validateDynamicFormSection } from '@/utility/utility';

export default {
  name: 'RegistrationUserToEvent',
  components: {
    Main,
    QuestionRender
  },
  mounted() {
    this.getEvent();
  },
  data() {
    const dataUser = getItem('userData');

    return {
      dataForm: {
        name: dataUser.nombre,
        lastname: dataUser.apellido_paterno,
        lastname2: dataUser.apellido_materno,
        email: dataUser.usuario,
        curp: dataUser.curp,
      },
      dynamicForm: null,
      subevents: getItem('subevents'),
      loading: true,
      sendFormLoading: false,
      showModal: false,
      addEventToCalendar: false,
      errors: {},
      eventID: useRoute().params.id || '',
      mode: useRoute().params.mode || '',
      answers: {},
      tabValue: 0,
      timer: null,
      router: useRouter(),
      sectionsRoute: [0],
      endForm: false,
    };
  },
  methods: {
    handleUpdateValue(value, newValue, question){
      value.content = newValue
      if(value.type === 'multipleChoice' || value.type == "dropDown" && question.goto){
        const option = question.options.find(o=>o.id === value.content);
        if(option && option.goto === "finish"){
          this.endForm = true;
        }else{
          this.endForm = false;
        }
      }
    },
    handleValidateTypeQuestion(questions, sectionIndex, next){
      if (!this.validateSection()){
        return;
      }
      const steps = next ? 1 : -1;
      //boton anterior
      if (!next) {
        this.sectionsRoute.pop();
        this.handleChangeSection(this.sectionsRoute[this.sectionsRoute.length - 1], steps)
        return
      }
      let question = null
      for (let i = questions.length - 1; i >= 0; i--) {
        if (questions[i].type === 'multipleChoice' || questions[i].type === 'dropDown') {
          question = questions[i];
          break;
        }
      }
      const optionSelect = this.answers[question?.id]?.content
      if (question) {
        if (question.goto) {
          const option = question.options.find(item => item.id === optionSelect)
          if (option.goto === 'next') {
            this.handleChangeSection( sectionIndex + steps, steps)
          } else if (option.goto === 'finish') {
            this.handleChangeSection( this.dynamicForm.sections.length - 1, 1)
          } else {
            const indextoGo = this.dynamicForm.sections.indexOf(this.dynamicForm.sections.find(item => item.id === option.goto))
            if(indextoGo != this.tabValue){
              this.handleChangeSection( indextoGo, steps)
            }
          }

        } else {
          this.handleChangeSection( sectionIndex + steps, steps)
        }
      } else {
        this.handleChangeSection( sectionIndex + steps, steps)
      }
    },
    async handleChangeSection(nextSection, value){
      if (value == 1) {
        this.tabValue = nextSection
        this.sectionsRoute.push(nextSection)
      } else {
        this.tabValue = nextSection
      }
      console.log(this.sectionsRoute)
    },

    onCancelModal() {
      this.showModal = false;
    },
    async searchCCT(event, question, sectionIdx, questionIdx){
      if (this.timer){
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        //SendRequest
        if (!event) {
          //props.setCCTLoading(false);
          return;
        }
        const response = await getQuestionLevel3CCT(event)
        if (response.success && response?.data?.length) {
          const data =  response?.data ? response.data[0] || {} : {}

          for (let i = 0; i < question.cct.length; i++) {
            const fields = question.cct[i].split('.'); //Split fields by '.'
            let value = data;
            fields.forEach(field => {
              value = value[field];
            })
            let cctFillQuestion = this.dynamicForm.sections[sectionIdx].questions[questionIdx + i + 1];
            this.answers[cctFillQuestion.id].content = value;
          }
          this.errors[question.id] = '';
        } else {
          this.errors[question.id] = 'La clave ingresada no es correcta';
        }
      }, 2000);

      this.answers[question.id].content = event

    },
    async validateSection() {
      this.errors = await validateDynamicFormSection(this.dynamicForm.sections[this.tabValue], this.answers);
      for (let error in this.errors) {
        if (this.errors[error])
          return false;
      }
      return true;
    },
    handleNextSection() {
      if (this.validateSection())
        this.tabValue++;
    },
    handlePreviousSection() {
      this.tabValue--;
    },
    async handleShowModalToSubmit() {
      if (this.dynamicForm && !await this.validateSection())
        return;
      this.showModal = true;
    },
    async handleSubmit(addEventToCalendar) {
      this.addEventToCalendar = addEventToCalendar;
      const data = {
        assistant: this.mode === 'assistant' ? true : false,
        answers: this.answers,
        subevents: this.subevents,
        eventToCalendar: addEventToCalendar,
        personal_register: true
      };
      const form = new FormData()
      this.formatData(form, data);
      this.sendFormLoading = true;
      if(data.subevents != null){
        this.sendFormWithSubevents(form);
      } else {
        this.sendFormWithOutSubevents(form);
      }
    },
    async sendFormWithSubevents(form) {
      await registerToEventWithSubevents(this.eventID, form).then((response) => {
        if (response.success) {
          removeItem('subevents')
          this.showNotificationAndRedirect('success', 'Éxito', response.message, response.data, this.eventID);
        } else {
          removeItem('subevents')
          this.showNotificationAndRedirect(response.cancelled ? 'warning' : 'error', 'Error', response.message);
        }
      }).catch(() => {
        this.showNotificationAndRedirect('error', 'Error', 'Oops ocurrió un error inesperado');
      });
      this.sendFormLoading = false;
    },
    async sendFormWithOutSubevents(form) {
      await registerToEvent(this.eventID, form).then((response) => {
        if (response.success) {
          removeItem('subevents')
          this.showNotificationAndRedirect('success', 'Éxito', response.message, response.data, this.eventID);
        } else {
          removeItem('subevents')
          this.showNotificationAndRedirect(response.cancelled ? 'warning' : 'error', 'Error', response.message);
        }
      }).catch(() => {
        this.showNotificationAndRedirect('error', 'Error', 'Oops ocurrió un error inesperado');
      });
      this.sendFormLoading = false;
    },
    formatData: function (form, data, prefix = '') {
      for (let key in data) {
          if ((typeof data[key] === 'object') && !(data[key] instanceof  File)) {
            this.formatData(form, data[key], `${prefix}${key}${prefix ? ']' : ''}[`);
          } else {
            if(typeof data[key] === 'boolean') data[key] = data[key] ? 1 : 0;
            form.append(`${prefix}${key}${prefix ? ']' : ''}`, data[key]);
          }
      }
    },
    handleCancel() {
      this.$router.go(-1);
    },
    showNotificationAndRedirect(type, message, description, eventResponse = null, eventID = null){
      Notification[type]({
        message,
        description,
      });
      if (type == 'success') {
        setTimeout(() => {
          this.router.push({ name: `responseRegister`, params: {module: this.mode === 'assistant' ? 'asistente' : 'participante', event: JSON.stringify(eventResponse), eventID} })
        }, 1000);
      }
    },
    async getEvent() {
      await getDetailsEvent(this.eventID).then((response) => {
        if (response.success) {
          //this.eventID = response.event.id
          if (response.event.activities===1 && this.mode === 'participant') {
            this.dynamicForm = JSON.parse(response.event.dynamic_form.questions_json);

            this.dynamicForm.sections.forEach(section => {
              section.questions.forEach(q => {
                this.answers[q.id] = {
                  type: q.type,
                  content: q.type == 'file' ? [] : '',
                };
              });
            });

          }
          this.loading = false;

        }
      }).catch((error) => {
        console.log(error);
      });

    },
  },
  async setup() {
    const { dispatch } = useStore();
    const formRef = ref();
    const mode = useRoute().params.mode || '';



    dispatch('changeTitleHeader', `Registro como ${mode === 'participant' ? 'participante' : 'asistente'}`);

    return {
      formatDate,
      formRef,

    };
  },

};

</script>

<style scoped lang='sass'>
.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin: 0 1rem

.participant-form
  border-radius: 21px
  background-color: #FFFFFF

  margin: 32px
  width: 100%
  height: auto

  .general-form
    padding: 32px 21px

  .event-form
    padding: 32px 21px

  .title
    font-family: 'Nutmeg Bold'
    color: #404040
    font-size: 18px


.form__buttons
  padding: 32px 21px

  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: 15px


.disabled
  color: #7B868C !important

.btn-fill-add
  border: 1px solid #9ED2EF
  color: #ffffff
  padding: 5px 15px 5px 15px
  background: #3FA7E1
  border-radius: 24px

button:hover
  cursor: pointer

.btn-outlined-exit
  border: 1px solid #636668
  color: #636668
  padding: 5px 15px 5px 15px
  background: none
  border-radius: 24px
  margin-left: 2rem

</style>

<style lang="sass">
.form-font
  .ant-form-item-label > label
    color: #7B868C
    font-family: Avenir Heavy
</style>

